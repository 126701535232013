import { resolveRelativePath as _resolveRelativePath } from '@manager/utils'

export const usePathResolution = (
  absolutePath = inject<string>('parentPath', ''),
) => {
  const idPathMap = useCurrentIdPathMap()

  /*
   * Path resolution
   */
  function resolveRelativePath(path: string): string
  function resolveRelativePath(path: string | undefined): string | undefined
  function resolveRelativePath(path: string | undefined): string | undefined {
    let _path = path

    // Resolve the indexes of the arrays in the path if any
    if (_path && absolutePath) {
      try {
        _path = _resolveRelativePath(_path, absolutePath)
      } catch (error) {
        console.error(error)
      }
    }

    return _path
  }
  const getAbsolutePathById = (
    id: number | null | undefined,
  ): string | undefined => {
    return id ? idPathMap.get(id) : undefined
  }
  const getRelativePathById = (
    id: number | null | undefined,
  ): string | undefined => {
    const path = getAbsolutePathById(id)
    return resolveRelativePath(path)
  }

  return {
    resolveRelativePath,
    getAbsolutePathById,
    getRelativePathById,
  }
}
