export function removeLastArrayAccess(str: string) {
  const matches = [...str.matchAll(/\[\d*]$/g)]
  if (matches.length > 0) {
    // Get the last match
    const lastMatch = matches[matches.length - 1]
    // Remove the last match
    return (
      str.substring(0, lastMatch.index) +
      str.substring(lastMatch.index + lastMatch[0].length)
    )
  }
  // No matches found, return the original string
  return str
}
